.button {
    border-color: #0C0B09;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
}

.button:hover {
    color: #ffffff;
}

.button::before {
    background-color: #0C0B09;
}