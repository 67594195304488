@keyframes zoomOutFadeIn {
    0% {
        opacity: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-transform: lowercase;
    overflow: hidden;
    padding: 112px 32px 0 32px;
    opacity: 0;
    transition: opacity .3s ease-in, overflow .3s 0s;
    z-index: 900;
    pointer-events: none;
}

.menu.is-visible {
    opacity: 1;
    overflow: auto;
    pointer-events: initial;
}

.menu,
.menu.themed-dark {
    background-color: rgba(12, 11, 9, .9);
    color: #ffffff;
}

.menu.themed-light {
    background-color: rgba(255, 255, 255, .97);
    color: #0C0B09;
}

.navigation {
    margin: auto;
    font-size: 3em;
    padding-bottom: 32px;
    position: relative;
}

.menu.is-visible .navigation ul {
    animation: zoomOutFadeIn .3s ease-in-out;
    display: block;
}

.navigation li {
    margin-top: .5em;
    margin-bottom: .5em;
    position: relative;
    transition:  opacity .3s ease-in-out;
    overflow: hidden;
    display: block;
}

.navigation li:hover {
    opacity: .66;
}
