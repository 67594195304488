@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.home.themed-dark {
    background-color: #0C0B09;
    color: #ffffff;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
    background-position: center center;
    transition: opacity .6s ease-in;
    opacity: 0;
}

.background.loaded {
    opacity: 1;

}

.content {
    margin: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}

.slogan-vector {
    max-width: 80%;
}

.themed-dark .portfolio-button {
    border-color: #ffffff;
}

.themed-dark .portfolio-button::before {
    background-color: #ffffff;
}

.home.themed-light .portfolio-button {
    border-color: #0C0B09;
}

.themed-light .portfolio-button:hover {
    color: #ffffff;
}

.themed-light .portfolio-button::before {
    background-color: #0C0B09;
}

.themed-dark .portfolio-button:hover {
    color: #0C0B09;
}
