.button {
    margin: 0;
    border: 1px solid;
    text-transform: lowercase;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 32px;
    text-align: center;
    transition: color .1s;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.button span {
    z-index: 100;
    position: relative;
    white-space: nowrap;
}

.button::before {
    display: block;
    content: "";
    width: 150%;
    padding-bottom: 150%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translateY(-100%) translateX(-100%);
    z-index: 0;
    opacity: 0;
    transition: opacity 3s ease-in-out;
}

.button:hover {
    transition: color .3s;
}

.button:hover::before {
    transition: transform .3s ease-out;
    opacity: 1;
    transform: translateY(-44%) translateX(-12.5%);
}
