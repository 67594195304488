.portfolio {
    display: flex;
    flex-direction: column;
    background-color: #0C0B09;
    color: #ffffff;
    justify-content: space-between;
    flex: 1;
}

.content {
    flex: 1;
}

.footer {
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
    color: #ffffff;
}

.help-text {
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    display: none;
}

.help-text::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-image:url('./../../images/show.svg');
    margin-right: 1em;
}

@media(max-width: 640px) {
    .help-text {
        display: flex;
    }
}