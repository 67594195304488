.error {
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.content {
    margin: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    text-align: center;
}

@media (max-width: 1536px) {
    .content {
        padding: 28px;
    }
}

@media (max-width: 1080px) {
    .content {
        padding: 24px;
    }
}

.error-message {
    margin-bottom: 2em;
    max-width: 480px;
    line-height: 1.42em;
    font-weight: 400;
}

.home-button {
    border-color: #0C0B09;
}

.home-button:hover {
    color: #ffffff;
}

.home-button::before {
    background-color: #0C0B09;
}
