.portfolio {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    color: #ffffff;
    justify-content: space-between;
    flex: 1;
}

.portfolio.themed-dark {
    background-color: #0C0B09;
    color: #ffffff;
}
