.wrapper {
    width: 100%;
    padding-bottom: 70.8333331%;
    position: relative;
    overflow: hidden;
}

.wrapper.loaded.is-ref::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url('./../../images/show.svg');
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 200;
    top: 50%;
    left: 50%;
    margin-left: -16px;
    margin-top: 0;
    width: 32px;
    height: 32px;
    background-size: 32px;
    opacity: 0;
}

.wrapper.loaded.is-ref::before {
    /*box-shadow: inset 0px 0px 50vw 0px rgba(12,11,9,0.75);*/
    background-color: #0C0B09;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: block;
    content: "";
    opacity: 0;
    transition: opacity .6s;
    pointer-events: none;
}

.wrapper.loaded.is-ref:hover::before {
    opacity: .50;
}

.wrapper.loaded.is-ref:hover::after {
    transition: margin-top .6s, opacity .6s;
    margin-top: -16px;
    opacity: 1;
}

.wrapper img {
    width: 100%;
    display: block;
    position: absolute;
    transition: transform .6s, opacity .3s ease-in;
    opacity: 0;
}

.wrapper.loaded.is-ref:hover img {
    /*transform: scale(1.025);*/
    transform-origin: 50% 50%;
}

.wrapper.loaded img {
    opacity: 1;
}
