.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.grid-image {
    max-width: 50%;
    width: 100%;
    background: #0C0B09;
}

@media (min-width: 641px) {
    .grid-image:nth-child(4n-2),
    .grid-image:nth-child(4n-1) {
        background: #22211F;
    }
}

@media (max-width: 640px) {
    .grid {
        flex-direction: column;
    }

    .grid-image {
        max-width: none;
    }

    .grid > div:nth-child(2n) {
        background: #22211F;
    }
}