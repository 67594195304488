body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
}

@media (max-width: 1536px) {
  body {
    font-size: 18px;
  }
}

@media (max-width: 1080px) {
  body {
    font-size: 16px;
  }
}
